import React, {useEffect} from "react";
import ImageCarousel from "../components/Carousel";
import { IconCont } from "../components/PhotoCont";


const AboutPage = () => {

    useEffect(() => {
        // Scroll to the top of the page when the component mounts
        window.scrollTo(0, 0);
    }, []);


    return(
        <div>
            
            <div className="home_about_box row" style={{position: 'relative'}}>
                <div style={{height: 'fit-content', width: '100%', overflow: "hidden"}}>
                    <ImageCarousel imageURL={['luciana4.jpg','luciana2.jpg', 'luciana3.jpg']}/>    
                </div>
                <div className="carousel_photo_text d-none d-md-block">Luciana Wellness & Recovery Homes</div>
                <div className="carousel_photo_text d-block d-md-none">Luciana Homes</div>
            </div>
            <div className="home_about_box row py-4">
                <div className="d-flex flex-column justify-content-start py-md-5 px-4">
                    <div className="row">
                        <div className="multi_heading col-6 col-md-12 order-2">
                            <span className="part_1 d-block">ABOUT US</span>
                        </div>
                    </div>
                    <div className="my-3">
                        <p>
                        We offer inpatient and outpatient counselling and treatment services.

                        The centre is managed by a group of health professionals including; consultant psychiatrist, addiction and psychological counsellors, nurses and social workers, all with proficiency in clients counselling, hospitality and administrative abilities. We are an equal opportunity provider.                        </p>
                    </div>
                </div>
            </div>           
            <div className="home_about_box row py-4 mt-4">
                <div className="col-md-6 col-12 d-flex flex-column justify-content-start py-md-5 px-4">
                    <div className="row">
                        <div className="multi_heading col-6 col-md-12">
                            <span className="part_1 d-block">OUR</span>
                            <span className="part_2 d-block">Mission</span>
                        </div>
                        <div className="col-6 d-md-none">
                            <div className="icon_box">
                                <IconCont imageURL="/images/mission.png"/>
                            </div>
                        </div>
                    </div>
                    <div className="my-3">
                        <p>
                        To provide highest quality therapeutic counselling and rehabilitation services to ensure those directly or indirectly affected by alcohol and drug abuse achieve their maximum functional recovery.
                        </p>
                    </div>
                </div>
                <div className="col-md-6 col-12 d-none d-md-flex ">
                    <div className="icon_box">
                        <IconCont imageURL="/images/mission.png"/>
                    </div>
                </div>
            </div>
            <div className="home_about_box row py-4">
                <div className="col-md-6 col-12 d-none d-md-flex ">
                    <div className="icon_box">
                        <IconCont imageURL="/images/vision.png"/>
                    </div>
                </div>
                <div className="col-md-6 col-12 d-flex flex-column justify-content-start py-md-5 px-4">
                    <div className="row">
                        <div className="multi_heading col-6 col-md-12 order-2">
                            <span className="part_1 d-block">OUR</span>
                            <span className="part_2 d-block">Vision</span>
                        </div>
                        <div className="col-6 d-md-none order-1">
                            <div className="icon_box">
                                <IconCont imageURL="/images/vision.png"/>
                            </div>
                        </div>
                    </div>
                    <div className="my-3">
                        <p>
                        To lead in revitalizing lives with compassion, innovation, and excellence.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutPage