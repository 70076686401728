import React, {useEffect} from "react";



const HelpPage = () => {

    const publicURL = process.env.PUBLIC_URL


    const call = () => {
        const phoneNumber = '+254716096413'

        if(/Mobi|Android/i.test(navigator.userAgent)){
            window.location.href = `tel:${phoneNumber}`
        }else{
            navigator.clipboard.writeText(phoneNumber)
            alert('Phone number copied to clipboard')
        }
    }

    useEffect(() => {
        // Scroll to the top of the page when the component mounts
        window.scrollTo(0, 0);
    }, []);


    const getDirections = () => {
        const address = '0.4640251,34.1123259'

        //const AppURL =  "comgooglemaps://?daddr=" + encodeURI(address);

        const WebURL = "https://www.google.com/maps/dir/?api=1&destination=" + encodeURI(address)

        window.open(WebURL, '_blank')
        
    }


    return (
        <div>
            <div className="text-center mt-5" style={{width: '100%', fontWeight: '600', fontSize: '2rem', color: '#2A4336'}}>Talk to us</div>
            <div className="home_about_box row py-4">
                <div className="col-md-5 col-12 d-flex justify-content-center">
                    <div className="d-flex flex-row">
                        <div className="px-5 py-3 mt-3 btn" onClick={call} style={{backgroundColor: '#2A4336', textAlign: 'center', boxShadow: '0 .4rem 4px #aaaaaa', borderRadius: '.4rem', fontWeight: 700, color: 'white'}}>
                            <span>Call us</span><br/>
                            <span>+254 716096413</span>
                        </div>
                    </div>
                </div>
                <div className="col-md-2 col-12 d-flex justify-content-center align-items-center my-5 my-md-auto"style={{fontWeight: 600, fontSize: '1.2rem'}}>or</div>
                <div className="col-md-5 col-12 d-flex justify-content-center">
                    <div className="d-flex flex-row">
                        <a href='mailto:lucianawellnesscenter@gmail.com' style={{textDecoration: 'none', outline: 'none'}}>
                        <div className="px-5 py-3 mt-3 btn" style={{backgroundColor: '#E88100', textAlign: 'center', boxShadow: '0 .4rem 4px #aaaaaa', borderRadius: '.4rem', fontWeight: 700, color: 'white'}}>
                            <span>Email us</span><br/>
                            <span>lucianawellnesscenter@gmail.com</span>
                        </div>
                        </a>
                    </div>
                </div>
            </div>
            <div className="home_about_box row py-4 pl-md-5 ml-md-5">
                <div className="col-12 mt-5">
                    <div className="d-flex flex-row mx-3">
                        <i className="fa fa-clock-o mr-4" style={{fontSize: '1.5rem'}} aria-hidden="true"></i>
                        <div className="mx-0">Our offices are open Monday to Friday, 8am to 6pm EAT</div>
                    </div><br/>
                    <div className="d-flex flex-row mx-3">
                        <i className="fa fa-calendar mr-4" style={{fontSize: '1.5rem'}} aria-hidden="true"></i>
                        <div className="mx-2">Luciana is open to residents and we take calls 24 hours a day, 7 hours a week, including holidays</div>
                    </div>
                </div>
                <div className="col-12 pl-md-auto pr-md-0 pr-4 pl-5 mt-5" style={{overflow: 'hidden'}}>
                    <span onClick={getDirections} className="dark_orange_text" style={{cursor: 'pointer'}}>Get Directions</span>
                    <div onClick={getDirections} style={{height: '8rem', maxWidth: '22rem', overflow: 'hidden', borderRadius: '.7rem', position: 'relative', cursor: 'pointer'}}>
                        <img src={publicURL + '/images/map.png'} style={{position: 'absolute', height: '100%', width: '100%', top: 0, left: 0}} alt=''/>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default HelpPage