import React, {useEffect} from "react";
import { useNavigate } from "react-router-dom";


const ErrorPage = () => {

    useEffect(() => {
        // Scroll to the top of the page when the component mounts
        window.scrollTo(0, 0);
    }, []);

    const navigate = useNavigate()

    return(
        <div className="d-flex flex-column justify-content-center align-items-center" style={{width: '100%', height: '20rem'}}>
            <div style={{fontSize: '2rem'}}>error 404 | Page not found</div>
            <div className="mt-5">
                <button style={{padding: '.7rem', backgroundColor: '#036936', color: 'white'}} onClick={() => navigate('/')}>
                    Back to home
                </button>
            </div>
        </div>
    )
}



export default ErrorPage