import React from "react"

const Footer = () => {
    const publicURL = process.env.PUBLIC_URL

    const scrollToTop= () => {
        window.scrollTo({top: 0, behavior: "smooth"})
    }

    return (
        <div className="pt-4" style={{backgroundColor: '#dedede'}}>
        <div className="footer_body px-2 px-md-0 py-3 pb-4">
            <div className="col-12 col-md-8 offset-md-2">
                <div className="d-flex flex-row">
                    <div className="py-2" style={{minHeight: '4rem'}}>
                        <div className="image_cont">
                            <img src={publicURL + '/images/bwlogo.png'} alt='logo'/>
                        </div>
                    </div>
                    <button className="button footer_btn px-4 py-2 my-auto" onClick={scrollToTop}>
                        <div className="nav_btn_content">To Top</div>
                    </button>
                </div>
                <hr style={{backgroundColor: '#888888'}}/>
                <div className="row">
                    <div className="d-flex flex-row col-md-6 col-12">
                        <a href='https://www.facebook.com/profile.php?id=100075824716697' style={{textDecoration: 'none'}}>
                            <i className="fa fa-facebook-official pt-3" aria-hidden="true" style={{color: '#888888', fontSize: '1.4rem'}}></i>
                        </a>
                        <a href='https://wa.me/+254716096413' style={{textDecoration: 'none'}}>
                            <i className="fa fa-whatsapp pt-3 ml-3" aria-hidden="true" style={{color: '#888888', fontSize: '1.4rem'}}></i>
                        </a>
                    </div>
                    <div className="d-flex flex-row col-md-6 col-12">
                        <span className="ml-auto pt-3" style={{color: '#888888'}}>Copyright &copy; 2023 Luciana Homes. All rights reserved</span>
                    </div>
                </div>
            </div>
        </div>
        </div>
    )
}

export default Footer