import React from "react"
import { useNavigate } from "react-router-dom"
import SlidingMenu from "./SlideMenu"

const NavBar = () => {
    const publicURL = process.env.PUBLIC_URL

    const navigate = useNavigate()

   

    return (
        <div className = 'navBar px-2 px-md-0'>
            <div className="nav_content col-12 col-md-8 offset-md-2 d-flex flex-row">
                <div className="image_cont" style={{pointerEvents: 'auto', cursor: 'pointer'}} onClick={() => navigate('/')}>
                    <img src={publicURL + '/images/logo.png'} alt='logo'/>
                </div>
                <div className="justify-self-end ml-auto d-none d-md-flex flex-row" style={{pointerEvents: 'auto'}}>
                    <div className="button" onClick={() => navigate('/')}>
                        <div className="nav_btn_content">Home</div>
                        <div className="highlighter"> </div>
                    </div>
                    <div className="button" onClick={() => navigate('/about')}>
                        <div className="nav_btn_content">About us</div>
                        <div className="highlighter"> </div>
                    </div>
                    <div className="button" onClick={() => navigate('/contact')}>
                        <div className="nav_btn_content">Contact</div>
                        <div className="highlighter"> </div>
                    </div>
                </div>
                <div className="justify-self-end ml-auto d-flex d-md-none" style={{height: '100%'}}>
                    <SlidingMenu/>
                </div>
            </div>
        </div>
    )
}



export default NavBar