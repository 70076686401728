import React, {useEffect} from "react";
import PhotoCont from "../components/PhotoCont";
import { PhotoContTwo } from "../components/PhotoCont";
import { useNavigate } from "react-router-dom";


const HomePage = () => {

    const publicURL = process.env.PUBLIC_URL


    const generatePrograms = () => {
        const genProgs = [];
        const  progs = ['Therapy', 'Family Support', 'In-patient & out-patient programs', 'Counseling services', 'Relapse prevention', 'Aftercare']

        for (let prog of progs){
            genProgs.push(
                <div className="col-12 col-md-4 px-1 py-3 p-md-3 f-flex justify-content-center">
                    <div className="prog_cont py-5 unselectable">{prog}</div>
                </div>
            )
        }

        return genProgs
    }

    const navigate = useNavigate()

    const call = () => {
        const phoneNumber = '+254716096413'

        if(/Mobi|Android/i.test(navigator.userAgent)){
            window.location.href = `tel:${phoneNumber}`
        }else{
            navigator.clipboard.writeText(phoneNumber)
            alert('Phone number copied to clipboard')
        }
    }

    const getDirections = () => {
        const address = '0.4640251,34.1123259'

        //const AppURL =  "comgooglemaps://?daddr=" + encodeURI(address);

        const WebURL = "https://www.google.com/maps/dir/?api=1&destination=" + encodeURI(address)

        window.open(WebURL, '_blank')
        
    }

    useEffect(() => {
        // Scroll to the top of the page when the component mounts
        window.scrollTo(0, 0);
    }, []);


    return(
        <div>
            <div className="home_theme_box row py-4">
                <div className="col-md-6 col-12 d-flex">
                    <div className="intro_text_cont">
                        <h2>Your optimal health, <br/> our expertise</h2>
                        <hr/>
                        <p><span>Luciana</span> wellness and recovery homes is a registered rehabilitation centre in Busia, Kenya. We are a leading provider of therapeutic counselling and treatment services.</p>
                        
                        <button className="call_to_action px-4 py-3" onClick={call}>
                            <i className="fa fa-phone mr-1" aria-hidden="true" style={{color: 'white'}}></i>
                            +254 716096413
                        </button>
                        
                    </div>
                </div>
                <div className="col-md-6 col-12 d-flex">
                    <div className="m-auto p-0">
                        <PhotoCont imageURL="/images/theme_image.png" dots/>
                    </div>
                </div>
            </div>
            <div className="home_about_box row py-4">
                <div className="col-md-6 col-12 d-flex flex-column justify-content-start py-5 px-4 order-md-2">
                    <div className="multi_heading">
                        <span className="part_1 d-block">OUR</span>
                        <span className="part_2 d-block">Facility</span>
                    </div>
                    <div className="my-3">
                        <p>
                            Welcome to Luciana, we offer inpatient and outpatient counselling and treatment services.
                        </p>
                    </div>
                    <div>
                        <button className="call_to_action px-5 py-3 py-md-4" onClick={() => navigate('/about')}>About us</button>
                    </div>
                </div>
                <div className="col-md-6 col-12 d-flex order-md-1">
                    <div className="m-auto p-0">
                        <div style={{width: '24rem', height: '20rem'}}>
                            <PhotoContTwo imageURL="/images/luciana1.jpg"/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="home_about_box mb-5 pt-5 px-5">
                <div className="header_cont mb-4">OUR TREATMENT PROGRAMS</div>
                <div className="row m-auto" style={{width: '100%'}}>
                   {generatePrograms()}
                </div>
            </div>

            <div className="home_about_box mt-5 pt-5 px-5">
                <div className="stats_cont mt-5 row mx-5">
                    <div className="col-12 col-md-3 text-center tp">
                        <span style={{color: '#036936', fontSize: '1.5rem'}}>Our stats</span>
                    </div>
                    <div className="col-12 col-md-3 text-center">
                        Recovered clients<br/><span>100+</span>
                    </div>
                    <div className="col-12 col-md-3 text-center">
                        Recovery rate<br/><span>95%</span>
                    </div>
                    <div className="col-12 col-md-3 text-center">
                        Professionals on call<br/><span>4</span>
                    </div>
                </div>
            </div>

            <div className="home_about_box mt-5 pt-5 px-5">
                <div className="row mx-md-5 px-md-5">
                    <div className="col-md-6 col-12">
                        <h5>Luciana Homes Address</h5>
                        <p>Bondeni <br/> Busia, Kenya</p>
                        <br/>
                        <p>Behind Busia Township primary school</p>
                    </div>
                    <div className="col-md-6 col-12 px-2" style={{overflow: 'hidden'}}>
                        <span onClick={getDirections} className="dark_orange_text" style={{cursor: 'pointer'}}>Get Directions</span>
                        <div onClick={getDirections} style={{height: '8rem', maxWidth: '22rem', overflow: 'hidden', borderRadius: '.7rem', position: 'relative', cursor: 'pointer'}}>
                            <img src={publicURL + '/images/map.png'} style={{position: 'absolute', height: '100%', width: '100%', top: 0, left: 0}} alt=''/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HomePage