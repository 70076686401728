import React from "react";

const PhotoCont = (props: {imageURL: string, dots: boolean }) => {
    const publicURL = process.env.PUBLIC_URL
    return(
        <div className='main_theme_photo_cont'>
            <div className="main_image">
                <img src={publicURL + props.imageURL} alt='theme'/>
            </div>
            <div className="image_dots">
                <img src={publicURL + '/images/dots.png'} alt=''/>
            </div>
        </div>
    )
}



export const PhotoContTwo = (props: {imageURL: string}) => {
    const publicURL = process.env.PUBLIC_URL
    return(
        <div className="photo_cont">
            <img src={publicURL + props.imageURL} alt='luciana homes'/>
        </div>
    )
}


export const IconCont = (props: {imageURL: string}) => {
    const publicURL = process.env.PUBLIC_URL
    return(
        <div className="icon_cont">
            <img src={publicURL + props.imageURL} alt='luciana homes'/>
        </div>
    )
}

export default PhotoCont