import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // Import the styles

const ImageCarousel = (props: {imageURL: string[]}) => {
  const publicURL = process.env.PUBLIC_URL

    

  return (
    // <Carousel showArrows={true} showThumbs={false} autoPlay={true} infiniteLoop={true}>
    //   <div className="photo_cont">
    //     <img src={publicURL + '/images/' + props.imageURL[0]} alt='luciana homes'/>
    //   </div>
    //   <div className="photo_cont">
    //     <img src={publicURL + '/images/' + props.imageURL[1]} alt='luciana homes'/>
    //   </div>
    //   <div className="photo_cont">
    //     <img src={publicURL + '/images/' + props.imageURL[2]} alt='luciana homes'/>
    //   </div>
    // </Carousel>

    <Carousel showArrows={true} showStatus={true} showThumbs={false} infiniteLoop={true} autoPlay={true}>
        <div className='carousel_photo_cont'>
            <img src={publicURL + '/images/' + props.imageURL[0]} alt=''/>
        </div>
        <div className='carousel_photo_cont'>
            <img src={publicURL + '/images/' + props.imageURL[1]} alt=''/>
        </div>
        <div className='carousel_photo_cont'>
            <img src={publicURL + '/images/' + props.imageURL[2]} alt=''/>
        </div>
    </Carousel>
  );
};

export default ImageCarousel;
