import React, {useState} from 'react';
import { slide as Menu } from 'react-burger-menu';

import { useNavigate } from 'react-router-dom';

const SlidingMenu = () => {


    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = (val: boolean) => {
        setIsOpen(val);
    };

    const navigate = useNavigate()
    
    const goTo = (link: string) => {
        toggleMenu(false)
        navigate(link)
    }

    return (
        <div>
        {
            isOpen ?
            <i className="fa fa-times my-auto btn" style={{fontSize: '2rem', pointerEvents: 'auto'}}  aria-hidden="true" onClick={() => toggleMenu(false)}></i>
            :
            <i className="fa fa-bars my-auto btn" style={{fontSize: '2rem', pointerEvents: 'auto'}} aria-hidden="true" onClick={() => toggleMenu(true)}></i>
        }

        <span className='dark_tint' style={{display: isOpen ? 'block' : 'none', pointerEvents: 'auto'}} onClick={() => toggleMenu(false)}></span>


        <Menu isOpen={isOpen} right>
            <div className='menu_button btn' onClick={() => goTo('/')}>Home</div>
            <div className='menu_button btn' onClick={() => goTo('/about')}>About us</div>
            <div className='menu_button btn' onClick={() => goTo('/contact')}>Contact</div>
        </Menu>
        </div>
    );
};

export default SlidingMenu;