import React from 'react';
import './lib/App.css';
import NavBar from './components/NavBar';
import Footer from './components/Footer';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import HomePage from './pages/HomePage';
import AboutPage from './pages/AboutPage';
import HelpPage from './pages/HelpPage';
import ErrorPage from './pages/Error404';


function App() {
  return (
    <>
    <Router>
    <NavBar/>
    <div className='cont_page row'>
      <div className="main_cont col-12 col-md-8 offset-md-2">
          <Routes>
            <Route path='/' element={<HomePage/>}/>
            <Route path='/about' element={<AboutPage/>}/>
            <Route path='/contact' element={<HelpPage/>}/> 
            <Route path='*' element={<ErrorPage/>}/>
          </Routes>
      </div>
    </div>
    <Footer/>
    </Router>
    </>
  );
}

export default App;
